import request from '@/utils/request'

// 活动数据一览
export function getActivity(data) {
  return request({
    url: '/system/activity/getActivityList',
    method: 'post',
    data
  })
}

// 数据导入
export function importActivity(data) {
  return request({
    url: '/system/activity/importProject',
    method: 'post',
    data
  })
}


// 活动数据删除
export function deleteActivity(data) {
  return request({
    url: '/system/activity/deleteActivityById',
    method: 'post',
    data
  })
}

// 活动数据删除
export function addActivity(data) {
  return request({
    url: '/system/activity/save',
    method: 'post',
    data
  })
}

// 活动数据单个查询
export function ActivityOne(data) {
  return request({
    url: '/system/activity/getActivityById',
    method: 'get',
    params: data
  })
}

// 活动基本数据保存
export function updateActivityOne(data) {
  return request({
    url: '/system/activity/update',
    method: 'post',
    data
  })
}

// 查询单个活动数据嘉宾信息
export function getGuest(data) {
  return request({
    url: '/system/activity/getGuestById',
    method: 'post',
    data
  })
}
// 新增嘉宾信息
export function addGuest(data) {
  return request({
    url: '/system/guest/save',
    method: 'post',
    data
  })
}
// 修改嘉宾信息
export function updateGuest(data) {
  return request({
    url: '/system/guest/update',
    method: 'post',
    data
  })
}
// 删除嘉宾信息
export function deleteGuest(data) {
  return request({
    url: '/system/guest/deleteGuestById',
    method: 'get',
    params: data
  })
}

// 查询单个活动数据赞助商信息
export function getSponsor(data) {
  return request({
    url: '/system/activity/getSponsorById',
    method: 'post',
    data
  })
}

// 新增赞助商信息
export function addSponsor(data) {
  return request({
    url: '/system/sponsor/save',
    method: 'post',
    data
  })
}

// 修改赞助商信息
export function updateSponsor(data) {
  return request({
    url: '/system/sponsor/update',
    method: 'post',
    data
  })
}

// 删除赞助商信息
export function deleteSponsor(data) {
  return request({
    url: '/system/sponsor/deleteSponsorById',
    method: 'get',
    params: data
  })
}

// 查询单个活动数据主办方信息
export function getOrganizer(data) {
  return request({
    url: '/system/activity/getOrganizerById',
    method: 'post',
    data
  })
}

// 新增主办方信息
export function addOrganizer(data) {
  return request({
    url: '/system/organizer/save',
    method: 'post',
    data
  })
}

// 修改主办方信息
export function updateOrganizer(data) {
  return request({
    url: '/system/organizer/updateOrganizer',
    method: 'post',
    data
  })
}

// 删除主办方信息
export function deleteOrganizer(data) {
  return request({
    url: '/system/organizer/deleteOrganizerById',
    method: 'get',
    params: data
  })
}

// 赞助商一览
export function getActivityList(data) {
  return request({
    url: '/system/sponsor/getActivityList',
    method: 'post',
    data
  })
}

// 主办方一览
export function getOrganizerList(data) {
  return request({
    url: '/system/organizer/getOrganizerList',
    method: 'post',
    data
  })
}


// 嘉宾一览
export function getGuestList(data) {
  return request({
    url: '/system/guest/getGuestList',
    method: 'post',
    data
  })
}

// 根据id查询赞助商信息
export function getActivityAndSponsorList(data) {
  return request({
    url: '/system/activity/getActivityAndSponsorList ',
    method: 'post',
    data
  })
}
// 根据id查询主办方信息
export function getActivityAndGuestList(data) {
  return request({
    url: '/system/activity/getActivityAndGuestList ',
    method: 'post',
    data
  })
}
// 根据id查询主办方信息
export function getActivityAndOrganizerList(data) {
  return request({
    url: '/system/activity/getActivityAndOrganizerList ',
    method: 'post',
    data
  })
}

// ------------------------

// 活动标签-新增
export function insertActivityLabel(data) {
  return request({
    url: '/system/activityLabel/insertActivityLabel',
    method: 'post',
    data
  })
}

// 活动标签信息详情 （参数：activityId）
export function selectActivityLabelDetail(data) {
  return request({
    url: '/system/activityLabel/selectActivityLabelDetail',
    method: 'get',
    params: data
  })
}

// 活动标签-编辑
export function updateActivityLabel(data) {
  return request({
    url: '/system/activityLabel/updateActivityLabel',
    method: 'post',
    data
  })
}



// 手动打标签编辑更新
export function updateActivityLabelManual(data) {
  return request({
    url: '/system/activityLabel/updateActivityLabelManual',
    method: 'post',
    data
  })
}

// 手动打标签
export function insertActivityLabelManual(data) {
  return request({
    url: '/system/activityLabel/insertActivityLabelManual',
    method: 'post',
    data
  })
}

// 查询当前活动所有的标签（参数：activityId）
export function getById(data) {
  return request({
    url: '/system/activityLabel/getById',
    method: 'get',
    params: data
  })
}

// 通过ID查询历史匹配标签--活动（参数：activityId）
export function findHistroyMatchedLabelByActivity(data) {
  return request({
    url: '/system/label/findHistroyMatchedLabelByActivity',
    method: 'get',
    params: data
  })
}

// 半自动标签新增编辑
export function insertAndUpdateActivityMatchedLabel(data) {
  return request({
    url: '/system/label/insertAndUpdateActivityMatchedLabel',
    method: 'post',
    data
  })
}
